.decal {
	padding: 4rem;
	padding-bottom: 0;
	text-align: center;
}
.fullpage {
	overflow-x: hidden;
	overflow-y: hidden;
}

.formContainer label {
	color: white;
	text-align: left;
	margin-left: 3rem;
	float: left;
}

.hidden-text {
	display: none;
}

.not-hidden-text {
	display: flex;
	text-align: center !important;
	font-size: 40px;
	padding: 11rem;
}

.hidden-form {
	display: none;
}

/* Style inputs with type="text", select elements and textareas */
input[type='text'],
select,
textarea {
	width: 90%; /* Full width */
	padding: 12px; /* Some padding */
	border: 1px solid black; /* Gray border */
	border-radius: 4px; /* Rounded borders */
	box-sizing: border-box; /* Make sure that padding and width stays in place */
	margin-top: 6px; /* Add a top margin */
	margin-bottom: 16px; /* Bottom margin */
	resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type='submit'] {
	align-items: left;
	text-align: left;
	background-color: white;
	color: 233748;
	padding: 12px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type='submit']:hover {
	background-color: gainsboro;
}

/* Add a background color and some padding around the form */
.formContainer {
	margin: auto;
	margin-top: rem;
	width: 70%;
	border-radius: 10px;
	background-color: #233748;
	padding: 20px;
}
.contactPage .clickUp p {
	font-size: 20px !important;
}

.contactPage .clickUp {
	top: 135rem;
	left: 60rem;
	right: 0;
	position: absolute;
}

.decal .row {
	text-align: center;
	margin: 0;
}

.decal h5 {
	font-size: 20px;
	color: black;
	margin: 0;
}

.decal .top-row {
	margin-top: 2rem;
	margin-bottom: 3.5rem;
}

.decal .middle-row {
	margin-top: 2rem;
	margin-bottom: 3.5rem;
}

.decal p {
	margin: 0 7rem;
}

.decal .bottom-row {
	display: flex;
	justify-content: space-around;
}

.bottom-space {
	padding-bottom: 40rem;
}

.contactForm button {
	background-color: white;
	border-radius: 0px;
	line-height: 0px;
	border-color: transparent;
	box-shadow: none;
	outline: none !important;
}

.transform {
	max-width: 70vw;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	-ms-transition: all 1s ease;
	transition: all 1s ease;
	position: absolute;
	margin-right: auto;
	margin-left: 23rem;
	margin-top: 7rem;
	left: 0;
	right: 0;
	text-align: center;
}

.transform-card {
	max-width: 70vw;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	-ms-transition: all 1s ease;
	transition: all 1s ease;
	position: absolute;
	margin-right: auto;
	margin-left: 8rem;
	margin-top: 7rem;
	left: 0;
	right: 0;
	text-align: center;
}

.slider {
	max-width: 60vw;
	position: absolute;
	margin-left: auto;
	margin-right: 34vw;
	margin-top: 7rem;
	left: 0;
	right: 0;
}

.contactPage {
	overflow-x: hidden;
}
.contact-desktop {
	display: flex;
}
.contact-mobile {
	display: none;
}

@media only screen and (max-width: 2900px) {
	.transform {
		max-width: 70vw;
		-webkit-transition: all 1s ease;
		-moz-transition: all 1s ease;
		-o-transition: all 1s ease;
		-ms-transition: all 1s ease;
		transition: all 1s ease;
		position: absolute;
		margin-right: auto;
		margin-left: 30rem;
		margin-top: 7rem;
		left: 0;
		right: 0;
		text-align: center;
	}

	.transform-card {
		max-width: 70vw;
		-webkit-transition: all 1s ease;
		-moz-transition: all 1s ease;
		-o-transition: all 1s ease;
		-ms-transition: all 1s ease;
		transition: all 1s ease;
		position: absolute;
		margin-right: auto;
		margin-left: 9rem;
		margin-top: 7rem;
		left: 0;
		right: 0;
		text-align: center;
	}

	.slider {
		max-width: 60vw;
		position: absolute;
		margin-left: 17rem;
		margin-right: 34rem;
		margin-top: 7rem;
	}

	.formContainer {
		margin: auto !important;
		width: 60%;
		border-radius: 10px;
		background-color: #233748;
		padding: 20px;
	}
}

@media only screen and (max-width: 1600px) {
	.transform {
		max-width: 70vw;
		-webkit-transition: all 1s ease;
		-moz-transition: all 1s ease;
		-o-transition: all 1s ease;
		-ms-transition: all 1s ease;
		transition: all 1s ease;
		position: absolute;
		margin-right: auto;
		margin-left: 25rem;
		margin-top: 7rem;
		left: 0;
		right: 0;
		text-align: center;
	}

	.transform-card {
		max-width: 70vw;
		-webkit-transition: all 1s ease;
		-moz-transition: all 1s ease;
		-o-transition: all 1s ease;
		-ms-transition: all 1s ease;
		transition: all 1s ease;
		position: absolute;
		margin-right: auto;
		margin-left: 9rem;
		margin-top: 7rem;
		left: 0;
		right: 0;
		text-align: center;
	}

	.slider {
		max-width: 60vw;
		position: absolute;
		margin-left: 10rem;
		margin-right: 10rem;
		margin-top: 7rem;
	}

	.contactPage .clickUp {
		top: 135rem;
		left: 63rem;
		right: 0;
		position: absolute;
	}

	.formContainer {
		margin: auto;
		margin-top: rem;
		width: 70%;
		border-radius: 10px;
		background-color: #233748;
		padding: 20px;
	}

	.not-hidden-text {
		margin-bottom: 7rem;
	}
}
@media only screen and (max-width: 1366px) {
	.contact-desktop {
		display: none;
	}
	.contact-mobile {
		display: flex;
	}
	.decal .top-row {
		margin-top: 0rem !important;
		margin-bottom: -4rem !important;
		padding-bottom: 0rem !important;
	}
}

@media only screen and (max-width: 1100px) {
	.contact-desktop {
		display: none;
	}
	.contact-mobile {
		display: flex;
	}
	.formContainer {
		margin: auto;
		margin-top: 20rem;
		width: 70%;
		border-radius: 10px;
		background-color: #233748;
		padding: 20px;
	}
	.formContainer p {
		margin-top: 2rem;
	}

	.formContainer {
		margin-top: 0rem;
	}

	.formContainer p {
		font-size: 24px !important;
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
	}

	.formContainer label {
		margin-left: 2rem !important;
	}

	.decal {
		padding-bottom: 0rem !important;
	}
	.decal .top-row {
		margin-top: 0rem !important;
		margin-bottom: -2rem !important;
		padding-bottom: 0rem !important;
	}

	input[type='text'],
	select,
	textarea {
		width: 90%; /* Full width */
		padding: 10px; /* Some padding */
		border: 1px solid black; /* Gray border */
		border-radius: 4px; /* Rounded borders */
		box-sizing: border-box; /* Make sure that padding and width stays in place */
		margin-top: 6px; /* Add a top margin */
		margin-bottom: 10px; /* Bottom margin */
		resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
	}
}

@media only screen and (max-width: 800px) {
	.not-hidden-text {
		padding: 2rem !important;
		font-size: 25px;
		text-align: center;
		margin-bottom: 0;
		line-height: 40px;
	}
	.contact-desktop {
		display: none;
	}
	.contact-mobile {
		display: flex;
	}
	.formContainer {
		margin: auto;

		width: 70%;
		border-radius: 10px;
		background-color: #233748;
		padding: 20px;
	}
	.formContainer p {
		margin-top: 2rem;
	}

	.formContainer {
		margin-top: 3rem !important;
	}

	.formContainer p {
		font-size: 15px !important;
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
	}

	.formContainer label {
		margin-left: 1rem !important;
	}

	.decal {
		padding-bottom: 0rem !important;
	}
	.decal .top-row {
		margin-top: 37rem !important;
		margin-bottom: -7rem !important;
		padding-bottom: 0rem !important;
	}

	input[type='text'],
	select,
	textarea {
		width: 90%; /* Full width */
		padding: 10px; /* Some padding */
		border: 1px solid black; /* Gray border */
		border-radius: 4px; /* Rounded borders */
		box-sizing: border-box; /* Make sure that padding and width stays in place */
		margin-top: 6px; /* Add a top margin */
		margin-bottom: 10px; /* Bottom margin */
		resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
	}
}

@media only screen and (max-width: 414px) {
	.contact-desktop {
		display: none;
	}
	.contact-mobile {
		display: flex;
	}
	.formContainer {
		margin: auto;
		margin-top: 40rem;
		width: 70%;
		border-radius: 10px;
		background-color: #233748;
		padding: 20px;
	}
	.formContainer {
		margin-top: 0rem;
	}

	.formContainer p {
		font-size: 15px !important;
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
	}

	.formContainer label {
		margin-left: 1rem !important;
	}

	.decal {
		padding-bottom: 0rem !important;
	}
	.decal .top-row {
		margin-top: 40em !important;
		padding-bottom: 0rem !important;
	}

	.decal .top-row p {
		font-size: 15px !important;
	}

	input[type='text'],
	select,
	textarea {
		font-size: 13px;
		width: 90%; /* Full width */
		padding: 10px; /* Some padding */
		border: 1px solid black; /* Gray border */
		border-radius: 4px; /* Rounded borders */
		box-sizing: border-box; /* Make sure that padding and width stays in place */
		margin-top: 6px; /* Add a top margin */
		margin-bottom: 10px; /* Bottom margin */
		resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
	}

	.formContainer {
		width: 85% !important;
	}
}

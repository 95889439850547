.footer-desktop {
	background-color: #233748;
	margin-top: 4rem;
	display: flex;
	padding: 2.5rem;
	.left-col {
		display: flex;
		padding: 0;
		.tbf-logo {
			border: 2px solid #ffffff;
			width: 146px;
			height: 146px;
			margin-right: 2rem;
		}
		.contact-info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-around;
			p {
				font-size: 16px;
				line-height: 19px;
				color: #ffffff;
				text-align: left;
			}
		}
	}
	.right-col {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		margin: 0 2rem;
		/* Uncomment and replace margin and align-items to keep links centered
		align-items: center; */
		.logo {
			margin-left: auto;
		}
		.footer-links {
			flex-direction: row;
			.footer-col {
				flex-direction: column;
				align-items: flex-start;
				p {
					font-size: 16px;
					line-height: 20px;
					color: #ffffff;
					text-align: left;
				}
			}
		}
	}
	.wdb-logo {
		position: absolute;
		top: 110px;
		right: 58px;
		height: 57px;
		width: 212px;
	}
}

.footer-mobile {
	display: none;
}

@media only screen and (max-width: 1100px) {
	.footer-mobile {
		padding-top: 2rem;
		background-color: #233748;
		display: flex;
		flex-direction: column;
		.row {
			margin: 1.5rem 1rem;
			width: auto;
		}
		.col {
			padding: 0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		.logo-row {
			border-top: 2px solid #ffffff;
			padding-top: 3rem;
			margin-top: 0;
		}
		p {
			font-size: 2vw !important;
			color: white;
			margin-bottom: 0.5rem;
			text-align: left;
		}
		.tbf-logo {
			border: 2px solid #ffffff;
			width: 20vw;
			height: 20vw;
		}
		.wdb-logo {
			width: 20vw;
			margin-top: 2rem;
		}
	}
	.footer-desktop {
		display: none;
	}
}
@media only screen and (max-width: 430px) {
	.footer-mobile {
		padding-top: 2rem;
		background-color: #233748;
		display: flex;
		flex-direction: column;
		.row {
			margin: 0rem 0.5rem 1rem 0.5rem;
			width: auto;
		}
		.col {
			padding: 0 !important;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		.logo-row {
			border-top: 2px solid #ffffff;
			padding-top: 2rem;
			margin-top: 0;
		}
		p {
			font-size: 3.5vw !important;
			color: white;
			margin-bottom: 0.5rem;
			text-align: left;
		}
		.contact-info {
			p {
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
		.tbf-logo {
			border: 2px solid #ffffff;
			width: 34vw;
			height: 34vw;
		}
		.wdb-logo {
			width: 34vw;
			margin-top: 2rem;
		}
	}
	.footer-desktop {
		display: none;
	}
}

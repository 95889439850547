.about-content {
	padding: 5rem;
	padding-bottom: 0;
	p {
		font-size: 140%;
		margin-right: 10rem !important;
		margin-left: 10rem !important;
	}
	.row {
		margin-bottom: 1rem;
	}
	.middle-row {
		margin-top: 2rem;
		margin-bottom: 10rem;
	}

	.bottom-navbar {
		display: flex;
		justify-content: space-between;
		align-content: center;
		margin-bottom: 5rem;
		a {
			
			font-family: 'Karla', sans-serif;
			border-radius: 6px;
			background-color: #233748;
			color: #ffffff;
			font-style: normal;
			font-weight: normal;
			line-height: 35px;
			text-align: center;
			text-transform: uppercase;
			border-color: transparent;
			box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.18);
			padding: 1vw 2vw 1vw 2vw;
			font-size: 2vw;
			margin: 0;
			text-decoration: none;
		}
	}
}

@media only screen and (max-width: 1400px) {
	.about-content {
		margin-top: 1rem;
		p {
			font-size: 120%;
			margin-right: 0rem !important;
			margin-left: 0rem !important;
		}
	}
}

@media only screen and (max-width: 1100px) {
	.about-content {
		margin-top: 2rem;
		p {
			font-size: 120%;
			margin-right: -0.5rem !important;
			margin-left: -0.5rem !important;
		}
	}
}

@media only screen and (max-width: 800px) {
	.about-content {
		margin-top: 3rem;
		p {
			font-size: 100%;
			margin-right: -1rem !important;
			margin-left: -1rem !important;
		}
	}
}

@media only screen and (max-width: 430px) {
	.about-content {
		p {
			font-size: 80%;
			margin-right: -1.5rem !important;
			margin-left: -1.5rem !important;
			margin-top: -2rem;
		}
		.bottom-navbar {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			height: 80vw;
			a {
				font-size: 100%;
			}
		}
		.middle-row {
			margin-bottom: 1rem;
		}
	}
}

.advisory-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	.introduction {
		width: 100%;
		margin: 0 0 3rem 0;
	}
	.committee-info {
		width: 100%;
		margin: 0;
		border: solid #233748 5px;
		p {
			text-align: left;
			padding: 2rem 2rem 1rem 4rem;
		}
	}
	.bottom-navbar {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-content: center;
		margin-bottom: 5rem;
		margin-top: 8rem;
		a {
			font-family: 'Karla', sans-serif;
			border-radius: 6px;
			background-color: #233748;
			color: #ffffff;
			font-style: normal;
			font-weight: normal;
			line-height: 35px;
			text-align: center;
			text-transform: uppercase;
			border-color: transparent;
			box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.18);
			padding: 1vw 2vw 1vw 2vw;
			font-size: 2vw;
			margin: 0;
			text-decoration: none;
		}
	}
}

@media only screen and (max-width: 430px) {
	.advisory-content {
		padding: 5rem;
		p {
			font-size: 100%;
		}
		.committee-info {
			border: solid #233748 5px;
			p {
				text-align: left;
				padding: 1rem 1rem;
			}
		}
		.bottom-navbar {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 50vw;
			margin-bottom: 5rem;
			margin-top: 4rem;
			a {
				font-size: 100%;
			}
		}
	}
}

.join {
	overflow: hidden;
}

.join .top {
	height: 50vw;
}

.join .join {
	color: white;
	padding: 2em;
}

.join .chunk {
	padding: em;
}

.join .bottom {
	margin: auto;
	margin-top: 6rem;

	justify-content: center;
	display: flex;
	text-align: center;
}

.join .bottom p {
	width: 80%;
	border-radius: 10px;
	border-color: transparent;
	box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.18);
	color: white;
	background-color: #233748;
	padding: 1.5em;
	font-size: 20px;
}

.join .bottom .title {
	font-weight: bold;
	font-size: 32px;
	margin-top: -1em;
	margin-bottom: 0em;
}

.join .youtube-player {
	position: absolute;
	top: 0;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	text-align: center;
	justify-content: center;
	width: 50%;
	height: 50%;
}

.join .youtube {
	display: flex;
	justify-content: center;
	margin: auto;
	margin-top: 2rem !important;
	margin-bottom: -20rem !important;
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%;
}

@media only screen and (max-width: 2800px) {
	.join .bottom {
		margin-top: 28rem;
		width: 70%;
		justify-content: center;
		display: flex;
		text-align: center;
	}
}

@media only screen and (max-width: 2000px) {
	.join .bottom {
		margin-top: 7rem;
		width: 70%;
		justify-content: center;
		display: flex;
		text-align: center;
	}
}

@media only screen and (max-width: 1900px) {
	.join .bottom {
		margin-top: 12rem;
		width: 70%;
		justify-content: center;
		display: flex;
		text-align: center;
	}
}

@media only screen and (max-width: 1500px) {
	.join .bottom {
		margin-top: 13rem !important;
		width: 70%;
		justify-content: center;
		display: flex;
		text-align: center;
	}
}

@media only screen and (max-width: 1400px) {
	.join .bottom {
		margin-top: 7rem !important;
		width: 70%;
		justify-content: center;
		display: flex;
		text-align: center;
	}
	.join .youtube-player {
		width: 70%;
		height: 70%;
	}
	.join .youtube {
		margin-top: 2rem !important;
		margin-bottom: -10rem !important;
	}
}

@media only screen and (max-width: 1400px) {
	.join .bottom {
		margin-top: -10rem;
		width: 80%;
		justify-content: center;
		display: flex;
		text-align: center;
	}
}

@media only screen and (max-width: 1145px) {
	.join .bottom {
		margin-top: 27rem !important;
		width: 80%;
		justify-content: center;
		display: flex;
		text-align: center;
	}
	.join .youtube-player {
		width: 80%;
		height: 80%;
	}
	.join .youtube {
		margin-top: 2rem !important;
		margin-bottom: 0rem !important;
	}
}

@media only screen and (max-width: 992px) {
	.join .bottom {
		margin-top: 20rem !important;
		width: 80%;
		justify-content: center;
		display: flex;
		text-align: center;
	}
}
@media only screen and (max-width: 576px) {
	.join .bottom {
		margin-top: 12rem;
		width: 80%;
		justify-content: center;
		display: flex;
		text-align: center;
	}

	.join .bottom p {
		font-size: 12px;
		text-align: center;
		margin: auto;
	}

	.join .bottom .title {
		font-weight: bold;
		font-size: 20px;
		margin-top: -1em;
		margin-bottom: -1rem;
	}
	.join .youtube-player {
		width: 80%;
		height: 80%;
	}
	.join .youtube {
		margin-top: 2rem !important;
		margin-bottom: 0rem !important;
	}
}
@media only screen and (max-width: 440px) {
	.join .bottom {
		margin-top: 11em !important;
		margin-bottom: 1rem;
		width: 85%;
		justify-content: center;
		display: flex;
		text-align: center;
	}

	.join .bottom p {
		font-size: 12px;
		text-align: center;
		margin: auto;
	}

	.join .bottom .title {
		font-weight: bold;
		font-size: 20px;
		margin-top: 1em;
		margin-bottom: 1rem;
		line-height: 30px;
	}

	.join .youtube-player {
		width: 80%;
		height: 80%;
	}
	.join .youtube {
		margin-top: 2rem !important;
		margin-bottom: 0rem !important;
	}
}

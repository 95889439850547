.blog-content {
	padding: 4rem;

	h2 {
		line-height: 4rem;
		text-align: left !important;
		margin-left: -1rem;
	}

	.header-rand {
		width: 100%;
		background-image: url(../img/smallText.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 100%;
	}

	.header-2020lineup {
		width: 100%;
		background-image: url(../img/2020lineup.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}

	.header-ellen {
		width: 100%;
		background-image: url(../img/ellen-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-cohost {
		width: 100%;
		background-image: url(../img/cohost-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-feng {
		width: 100%;
		background-image: url(../img/feng-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-discourse {
		width: 100%;
		background-image: url(../img/discourse-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-fall2019 {
		width: 100%;
		background-image: url(../img/2019lineup-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-vsauce {
		width: 100%;
		background-image: url(../img/vsauce-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-homeland {
		width: 100%;
		background-image: url(../img/homeland-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-politico {
		width: 100%;
		background-image: url(../img/vandehei-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}

	.header-press {
		width: 100%;
		background-image: url(../img/press-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-2019spring {
		width: 100%;
		background-image: url(../img/spring2019-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-none {
		width: 100%;
		background-image: url(../img/blank-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
}

.blog-content p {
	margin: 1rem 8rem;
	font-size: 120%;
	text-align: left;
	display: block;
}

.blog-content .italics {
	font-style: italic;
}

.blog-content .underline {
	text-decoration: underline;
}

.blog-content h2 {
	align-items: center;
	text-align: center;
	padding-top: 16rem;
	padding-bottom: 4rem;
	padding-left: 3rem;
	font-size: 50px;
}

.blog-content h3 {
	align-items: center;
	text-align: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
	margin: auto;
	font-size: 30px;
}

.blog-content .right-column {
	margin-left: -25rem;
}

@media only screen and (max-width: 2800px) {
	.blog-content {
		padding: 4rem;

		h2 {
			line-height: 4rem;
			text-align: left !important;
			margin-left: 0rem;
			padding: none;
			margin-right: 2rem;
		}
		p {
			margin-left: 10rem;
			margin-right: 10rem;
			margin-top: 2rem;
		}
	}
}
@media only screen and (max-width: 1600px) {
}
@media only screen and (max-width: 1366px) {
	.blog-content {
		h2 {
			padding-bottom: -5rem;
			margin-top: -3rem;
		}
	}
}
@media only screen and (max-width: 1110px) {
	.blog-content {
		h2 {
			margin-top: -8rem;
			font-size: 37px;
			line-height: 40px;
		}

		p {
			margin-left: 3rem;
			margin-right: 3rem;
		}

		.header-rand {
			background-position: 50% 69%;
		}

		.header-2020lineup {
			background-position: 50% 69%;
		}

		.header-ellen {
			background-position: 50% 69%;
		}
		.header-cohost {
			background-position: 50% 69%;
		}
		.header-feng {
			background-position: 50% 69%;
		}
		.header-discourse {
			background-position: 50% 69%;
		}
		.header-fall2019 {
			background-position: 50% 82%;
		}
		.header-vsauce {
			background-position: 50% 69%;
		}
		.header-homeland {
			background-position: 50% 69%;
		}
		.header-politico {
			background-position: 50% 69%;
		}

		.header-press {
			background-position: 50% 69%;
		}
		.header-2019spring {
			background-position: 50% 69%;
		}
		.header-none {
			background-position: 50% 82%;
		}
	}
}
@media only screen and (max-width: 800px) {
	.blog-content {
		h2 {
			margin-top: -15rem;
			font-size: 30px;
			line-height: 40px;
			margin-left: -1rem;
		}

		p {
			margin-left: 3rem;
			margin-right: 3rem;
		}

		.header-rand {
			background-position: 50% 80%;
		}

		.header-2020lineup {
			background-position: 50% 80%;
		}

		.header-ellen {
			background-position: 50% 80%;
		}
		.header-cohost {
			background-position: 50% 80%;
		}
		.header-feng {
			background-position: 50% 80%;
		}
		.header-discourse {
			background-position: 50% 80%;
		}
		.header-fall2019 {
			background-position: 50% 91%;
		}
		.header-vsauce {
			background-position: 50% 80%;
		}
		.header-homeland {
			background-position: 50% 80%;
		}
		.header-politico {
			background-position: 50% 80%;
		}

		.header-press {
			background-position: 50% 80%;
		}
		.header-2019spring {
			background-position: 50% 80%;
		}
		.header-none {
			background-position: 50% 91%;
		}
	}
}
@media only screen and (max-width: 440px) {
	.header-rand {
		width: 100%;
		background-image: url(../img/smallText.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 980%;
	}

	.header-2020lineup {
		width: 100% !important;
		background-image: url(../img/2020lineup.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98% !important;
	}

	.header-ellen {
		width: 100%;
		background-image: url(../img/ellen-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-cohost {
		width: 100%;
		background-image: url(../img/cohost-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-feng {
		width: 100%;
		background-image: url(../img/feng-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-discourse {
		width: 100%;
		background-image: url(../img/discourse-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-fall2019 {
		width: 100%;
		background-image: url(../img/2019lineup-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-vsauce {
		width: 100%;
		background-image: url(../img/vsauce-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-homeland {
		width: 100%;
		background-image: url(../img/homeland-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-politico {
		width: 100%;
		background-image: url(../img/vandehei-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}

	.header-press {
		width: 100%;
		background-image: url(../img/press-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-2019spring {
		width: 100%;
		background-image: url(../img/spring2019-blog.png);
		background-position: 50% 40%;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.header-none {
		width: 100%;
		background-image: url(../img/blank-blog.png);
		background-position: 40% 80% !important;
		background-repeat: no-repeat;
		background-size: 98%;
	}
	.blog-content {
		padding: 4rem;
		h2 {
			line-height: 19px !important;
			text-align: left !important;
			margin-left: -1rem !important;
			font-size: 11px !important;
			padding-left: 2rem !important;
			line-height: 4rem;
			text-align: left !important;
			margin-left: 0rem;
			padding: none;
			margin-right: 2rem;
			margin-top: -17rem;
			margin-bottom: -3rem;
		}
		p {
			font-size: 14px;
			margin-left: 0rem !important;
			margin-right: 0rem !important;
			margin-top: 2rem;
		}

		.italics {
			margin-top: 0rem;
		}
	}
}

.whole-comp {
	margin-bottom: 0rem;
}

.stuff {
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: 50% 20%;
	width: 100%;
	height: 100vh;
	position: relative;
	display: inline-block;
}

.bar button {
	background-color: white !important;
	outline: none !important;
}

.scrolling-show {
	display: flex;
}

.header-mobile {
	display: none;
}
.header-desktop {
	display: block;
}

.hidbutton {
	margin-left: -100rem;
}

.scrolldown {
	display: flex;
}

.stuff h2 {
	margin-top: 22vw !important;
}

.stuff {
	.btn-default {
		color: white;
		background: none;
		margin-top: 17rem;
		font-size: 70px;
	}
	.btn-default:hover {
		color: grey;
	}
}

.space {
	background-size: 100%;
	background-position: left;
	background-repeat: no-repeat;
	width: 100%;
	height: 7em;
	position: relative;
	display: inline-block;
}

.header-desktop {
	width: 90%;
	margin-left: 5%;
	position: relative;
	text-align: center;
	background-color: #233748;
	color: white;
	border-radius: 6px;
}

.header-desktop h1 {
	margin: 0 0 0 4rem;
	padding-top: 1rem;
	text-align: left;
}

.header-desktop .link-tag:hover {
	text-decoration: none;
}

.header-desktop .landing-link:hover {
	text-decoration: none;
}

.header-desktop .nav-text {
	justify-content: space-between;
	text-align: left !important;
	font-size: 18px;
	color: white;
	padding: 0;
	margin: 0 2.6rem;
	letter-spacing: 0.11em;
	text-transform: uppercase;
	justify-content: center !important;
}

.header-desktop .bar .mm {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.header-desktop p:after {
	text-decoration: none;
	display: block;
	content: '';
	border-bottom: solid 1px #ffffff;
	transform: scaleX(0);
	transition: transform 350ms ease-in-out;
}

.header-desktop p:hover:after {
	transform: scaleX(1);
}
.header-desktop p.nav-text:after {
	transform-origin: 0% 50%;
}

.header-desktop .dropdown {
	position: relative;
	display: block;
	text-align: center !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center;
}

.header-desktop .dropdown-content-events {
	display: none;
	text-align: center !important;
	align-items: center !important;
	justify-content: center !important;
	position: absolute;
	background-color: #233748;
	min-width: 110px !important;
	z-index: 1;
	transition: 0.5s ease;
	margin-top: 3rem;
}

.header-desktop .dropdown-content-about {
	display: none;
	justify-content: center;
	text-align: center;
	position: absolute;
	background-color: #233748;
	min-width: 160px;
	z-index: 1;
	transition: 0.5s ease;
	margin-top: 3rem;
}

.header-desktop .link-tag {
	justify-content: center !important;
	text-align: center !important;
	position: relative;
	padding-bottom: 0.5rem;
	display: block;
	margin-top: 1rem;
	margin-bottom: 0.2rem;
}

.header-desktop .dropdown:hover .dropdown-content-events {
	padding: 0rem;
	display: block;
}

.header-desktop .dropdown:hover .dropdown-content-about {
	padding: 0rem;
	display: block;
}
.header .bar {
	background-color: #233748;
	color: white;
	align-items: center;
	text-align: center;
	justify-content: center;
	border-radius: 10px;
}

.header-desktop .navbar {
	color: white;
	display: flex;
	justify-content: space-between;
	padding-left: 2rem;
	padding-right: 10rem;
	width: 100%;
}

.header-desktop .navbar .aboutLink {
	color: white;
	font-size: large;
}

.header-desktop .bar .dropdown:hover {
	color: white;
	display: block;
}

.header-desktop .socials-tassle {
	object-fit: fill;
	position: absolute;
	right: 50px;
	top: -3px;
}

/* LANDING PAGE HEADER*/

.landing-header {
	.carousel {
		height: 100vh;
		overflow: hidden;

		z-index: 0;
		.carousel-caption {
			z-index: 0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			height: 60%;
			h3 {
				text-align: left;
				text-transform: none;
				font-family: Karla;
				font-style: normal;
				font-weight: bold;
				font-size: 77px;
				line-height: 90px;
				/* identical to box height */
				color: #ffffff;
				text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.73);
			}
			a {
				font-family: 'Karla', sans-serif;
				border-radius: 6px;
				background-color: #233748;
				color: #ffffff;
				font-style: normal;
				font-weight: normal;
				line-height: 35px;
				text-transform: uppercase;
				border-color: transparent;
				box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.18);
				padding: 0.5vw 2vw 0.5vw 2vw;
				font-size: 2vw;
				margin: 0;
				text-decoration: none;
			}
		}
	}
}

/* RESPONSIVE SCROLL LINKS */
@media only screen and (max-width: 2900px) {
	.stuff {
		.btn-default {
			margin-top: 22rem;
		}
	}
}
@media only screen and (max-width: 1600px) {
	.stuff {
		.btn-default {
			margin-top: 17rem;
		}
	}
}

@media only screen and (max-width: 1400px) {
	.btn-default {
		display: none !important;
	}
	.scrolldown {
		display: none !important;
	}

	.scrolling-show {
		display: none !important;
	}
}

@media only screen and (max-width: 1100px) {
	.scrolldown {
		display: none;
	}

	.scrolling-show {
		display: none;
	}
	.stuff {
		margin-bottom: -8rem !important;
		background-size: 140% !important;
		background-position: 50% 0%;
		height: 100vw !important;
		h2.text {
			font-size: 40px !important;
			margin-top: 40vw !important;
		}
	}
}

@media only screen and (max-width: 1100px) {
	.scrolldown {
		display: none;
	}

	.scrolling-show {
		display: none;
	}
	.stuff {
		margin-bottom: -40rem;
		background-size: 140% !important;
		background-position: 50% 0%;
		height: 100vw !important;
		h2.text {
			font-size: 50px !important;
			margin-top: 55vw !important;
		}
	}
}

/* RESPONSIVE HEADER*/

@media only screen and (max-width: 430px) {
	.header {
		width: 95% !important;
		margin-left: 2.5% !important;
		h1 {
			margin: 0.4rem 0 0 1.3rem !important;
			margin-right: 10rem !important;
			width: 100%;
			font-size: 20px !important;
			padding-bottom: 0;
		}
	}
	.landing-header {
		z-index: -1;
		.carousel {
			height: 100vw;

			z-index: 0;
			.carousel-caption {
				z-index: 0;
				justify-content: space-around;
				h3 {
					font-size: 6vw;
				}
				a {
					line-height: 100%;
					font-size: 4vw;
					padding: 0.5rem;
				}
			}
			.carousel-inner {
				height: 100vw;
				img {
					height: 100vw;
					object-fit: cover;
				}
			}
		}
	}

	.navbar-toggler-icon {
		font-size: 12px !important;
	}
	.whole-comp {
		margin-bottom: -15rem;
	}
	.stuff {
		margin-bottom: -40rem;
		background-size: 140% !important;
		background-position: 50% 0%;
		height: 100vw !important;
		h2.text {
			font-size: 40px !important;
			margin-top: 50vw !important;
		}
	}

	.btn-default {
		display: none !important;
	}
	.scrolldown {
		display: none !important;
	}

	.scrolling-show {
		display: none !important;
	}
}

/* RESPONSIVE CAROUSEL 1100 px WIDTH */
@media only screen and (max-width: 1100px) {
	.landing-header {
		z-index: -1;
		.carousel {
			height: 100vw;

			z-index: 0;
			.carousel-caption {
				z-index: 0;
				justify-content: space-around;
				h3 {
					font-size: 5vw;
					color: white;
				}
				a {
					line-height: 100%;
					font-size: 4vw;
					padding: 0.5rem;
				}
			}
			.carousel-inner {
				height: 100vw;
				img {
					height: 100vw;
					object-fit: cover;
				}
			}
		}
	}
}

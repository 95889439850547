.landing {
	.landing-content {
		padding: 5rem;
		p {
			font-size: 21px;
			text-align: left;
			margin-left: 10rem;
			margin-right: 10rem;
		}
		.row {
			justify-content: left;
		}
	}

	.youtube {
		display: flex;
		justify-content: center;
		margin: auto;
		margin-top: 2rem !important;
		margin-bottom: -30rem !important;
		position: relative;
		overflow: hidden;
		width: 100%;
		padding-top: 56.25%;
		.youtube-player {
			position: absolute;
			top: 0;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			text-align: center;
			justify-content: center;
			width: 50%;
			height: 50%;
		}
	}
}

@media only screen and (max-width: 1500px) {
	.landing {
		.youtube-player {
			width: 70%;
			height: 70%;
		}
		.youtube {
			margin-top: 2rem !important;
			margin-bottom: -20rem !important;
		}
	}
}
@media only screen and (max-width: 1145px) {
	.landing {
		p {
			font-size: 21px;
			text-align: left;
			margin-left: 1rem !important;
			margin-right: 1rem !important;
		}

		.youtube-player {
			width: 80%;
			height: 80%;
		}
		.youtube {
			margin-top: -1rem !important;
			margin-bottom: -10rem !important;
		}
	}
	@media only screen and (max-width: 700px) {
		.landing {
			.youtube-player {
				width: 80% !important;
				height: 80% !important;
			}
			.youtube {
				margin-top: -2rem !important;
				margin-bottom: -3rem !important;
			}
		}
	}
}

@media only screen and (max-width: 430px) {
	.landing {
		.landing-content {
			padding: 3.3rem;
			p {
				font-size: 70%;
				padding: 0rem !important;
			}
		}
	}

	.landing {
		.youtube-player {
			width: 80% !important;
			height: 80% !important;
		}
		.youtube {
			margin-top: -2rem !important;
			margin-bottom: 0rem !important;
		}
	}
}

.header {
	margin-top: 2rem;
	width: 95%;
	margin-left: 2.5%;
	position: absolute;
	z-index: 2;
	text-align: center;
	background-color: #233748;
	color: white;
	border-radius: 6px;
}

.header-mobile {
	display: none;
}

.header h1 {
	margin: 0 0 0 4.4rem;
	padding-top: 1rem;
	text-align: left;
}

.header {
	.link-tag:hover {
		text-decoration: none;
	}

	.landing-link:hover {
		text-decoration: none;
	}

	.stuff h2 {
		margin-top: 15vw;
	}

	.nav-text {
		justify-content: space-between;
		text-align: center !important;
		font-size: 18px;
		color: white;
		padding: 0;
		margin: 0 2.6rem;
		letter-spacing: 0.11em;
		text-transform: uppercase;
		justify-content: center !important;
	}

	.bar .mr-auto {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	p:after {
		text-decoration: none;
		display: block;
		content: '';
		border-bottom: solid 1px #ffffff;
		transform: scaleX(0);
		transition: transform 350ms ease-in-out;
	}

	p:hover:after {
		transform: scaleX(1);
	}
	p.nav-text:after {
		transform-origin: 0% 50%;
	}

	.dropdown {
		position: relative;
		display: flex;
		text-align: center !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center;

		.toggle-head,
		#parent {
			display: flex;
			position: relative;
			.dropdown-arrow {
				position: absolute;
				margin-left: 9.3rem;
				margin-top: 0.43rem;
				width: 8px;
				height: 12px;
				transition: 0.5s;
				-webkit-transition: 0.5s;
				-moz-transition: 0.5s;
				-ms-transition: 0.5s;
				-o-transition: 0.5s;
			}
		}

		.toggle-head,
		#other {
			display: flex;
			position: relative;
			.dropdown-arrow2 {
				position: absolute;
				margin-left: 7.9rem;
				margin-top: 0.43rem;
				width: 8px;
				height: 12px;
				transition: 0.5s;
				-webkit-transition: 0.5s;
				-moz-transition: 0.5s;
				-ms-transition: 0.5s;
				-o-transition: 0.5s;
			}
		}
	}

	#parent:hover .dropdown-arrow {
		transition: 0.5s;
		-webkit-transition: 0.5s;
		-moz-transition: 0.5s;
		-ms-transition: 0.5s;
		-o-transition: 0.5s;
		-webkit-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		-o-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg);
	}

	#other:hover .dropdown-arrow2 {
		transition: 0.5s;
		-webkit-transition: 0.5s;
		-moz-transition: 0.5s;
		-ms-transition: 0.5s;
		-o-transition: 0.5s;
		-webkit-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		-o-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg);
	}
	.dropdown-content-events {
		display: none;
		text-align: center !important;
		align-items: center !important;
		justify-content: center !important;
		position: absolute;
		background-color: #233748;
		min-width: 110px !important;
		z-index: 1;
		transition: 0.5s ease;
		margin-top: 3rem;
	}

	.dropdown-content-about {
		display: none;
		justify-content: center;
		text-align: center;
		position: absolute;
		background-color: #233748;
		min-width: 160px;
		z-index: 1;
		transition: 0.5s ease;
		margin-top: 3rem;
	}

	.link-tag {
		justify-content: center !important;
		text-align: center !important;
		position: relative;
		padding-bottom: 0.5rem;
		display: block;
		margin-top: 1rem;
		margin-bottom: 0.2rem;
	}

	.dropdown:hover .dropdown-content-events {
		padding: 0rem;
		display: block;
	}

	.dropdown:hover .dropdown-content-about {
		padding: 0rem;
		display: block;
	}
	.bar {
		background-color: #233748;
		color: white;
		align-items: center;
		text-align: center;
		justify-content: center;
		border-radius: 6px;
	}

	.navbar {
		color: white;
		display: flex;
		justify-content: space-between;
		padding-left: 2rem;
		padding-right: 10rem;
		width: 100%;
	}

	.navbar .aboutLink {
		color: white;
		font-size: large;
	}

	.bar .dropdown:hover {
		color: white;
		display: block;
	}

	.header-desktop .social-links {
		display: flex;
		width: 44px;
		border-radius: 6px;
		height: 176px;
		position: absolute;
		right: 50px;
		top: -3px;
		justify-content: center;
		.socials-tassle {
			position: initial;
			width: 44px;
			border-radius: 6px;
			height: 176px;
			object-fit: fill;
		}
		.icons {
			margin-right: 1px;
			position: absolute;
			top: 0;
			display: flex;
			flex-direction: column;
			a {
				z-index: 2;
				top: 13px;
				left: 9px;
				margin-top: 12.13px;
				img {
					height: 25px;
					width: 25px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1600px) {
	.header .nav-text {
		color: white;
		font-size: 16px;
		text-transform: uppercase;
		letter-spacing: 0.11em;
		margin-left: 2.7rem;
	}
	.header .nav-background {
		width: 690px;
	}
	.dropdown-arrow {
		position: absolute;
		margin-left: 8.5rem !important;
		margin-top: 0.32rem !important;
	}
	.dropdown-arrow2 {
		position: absolute;
		margin-left: 7.3em !important;
		margin-top: 0.32rem !important;
	}
}

@media only screen and (max-width: 1366px) {
	.header .bar {
		width: 50% !important;
		margin-left: 2.5% !important;
	}

	.header-mobile {
		display: flex;

		h1 {
			font-size: 20px;
			margin: 0.4rem 0 0 2rem !important;
			margin-right: 5.6rem !important;
			width: 100%;
			font-size: 20px !important;
			padding-bottom: 0;
		}
	}
	.header .nav-text {
		color: white;
		font-size: 16px;
		text-transform: uppercase;
		letter-spacing: 0.11em;
		margin-left: 1rem;
		text-align: left !important;
	}

	.mr-auto {
		margin-top: 2rem;
		margin-left: -27.7rem;
		.link-tag {
			width: 80%;
		}
		.nav-text {
			margin-right: 1rem;
			font-size: 13px;
		}
	}
	.mobile-tassle {
		display: inline-block;
		margin-left: -7rem;
		margin-top: -2rem;
		.icon-pic {
			margin-right: 4rem;
		}
	}

	.header-desktop {
		display: none;
	}

	.header .navbar {
		padding-right: 2rem;
	}
	.stuff {
		height: 60vh;
	}
}

@media only screen and (max-width: 1250px) {
	.header .bar {
		width: 50% !important;
		margin-left: 2.5% !important;
	}

	.header-mobile {
		display: flex;

		h1 {
			font-size: 20px;
			margin: 0.4rem 0 0 3rem !important;
			margin-right: 5.6rem !important;
			width: 100%;
			font-size: 20px !important;
			padding-bottom: 0;
		}
	}
	.header .nav-text {
		color: white;
		font-size: 16px;
		text-transform: uppercase;
		letter-spacing: 0.11em;
		margin-left: 0.3rem;
		text-align: left !important;
	}

	.mr-auto {
		margin-top: 2rem;
		margin-left: -27.3rem;
		.link-tag {
			width: 80%;
		}
		.nav-text {
			margin-right: 1rem;
			font-size: 13px;
		}
	}
	.mobile-tassle {
		display: inline-block;
		margin-left: -9rem;
		margin-top: -2rem;
		.icon-pic {
			margin-right: 4rem;
		}
	}

	.header-desktop {
		display: none;
	}

	.header .navbar {
		padding-right: 2rem;
	}
	.stuff {
		height: 90vh;
	}
}

@media only screen and (max-width: 1100px) {
	.header .bar {
		width: 50% !important;
		margin-left: 2.5% !important;
	}

	.header-mobile {
		display: flex;

		h1 {
			font-size: 20px;
			margin: 0.4rem 0 0 1.6rem !important;
			margin-right: 5.6rem !important;
			width: 100%;
			font-size: 20px !important;
			padding-bottom: 0;
		}
	}
	.header .nav-text {
		color: white;
		font-size: 10.4px !important;
		text-transform: uppercase;
		letter-spacing: 0.11em;
		margin-left: 0.3rem;
	}

	.mr-auto {
		margin-top: 2rem;
		margin-left: -26.6rem;
		.link-tag {
			width: 80%;
		}
		.nav-text {
			margin-right: 1rem;
			font-size: 13px;
		}
	}
	.mobile-tassle {
		display: inline-block;
		margin-left: -9rem;
		margin-top: -2rem;
		.icon-pic {
			margin-right: 4rem;
		}
	}

	.header-desktop {
		display: none;
	}

	.header .navbar {
		padding-right: 2rem;
	}
	.stuff {
		height: 40vh;
	}
}

@media only screen and (max-width: 991px) {
	.header .nav-text {
		color: white;
		font-size: 12px !important;
		text-transform: uppercase;
		letter-spacing: 0.11em;
		margin-left: 0.3rem;
	}
	.header .bar {
		width: 90% !important;
		margin-left: 2.5% !important;
	}

	.header-mobile {
		display: flex;

		h1 {
			font-size: 20px;
			margin: 0.4rem 0 0 3rem !important;
			margin-right: 25rem !important;
			width: 100%;
			font-size: 20px !important;
			padding-bottom: 0;
		}
	}

	.mr-auto {
		margin-top: 2rem;
		margin-left: -37.3rem;
		.link-tag {
			width: 80%;
		}
		.nav-text {
			margin-right: 1rem;
			font-size: 13px;
		}
	}
	.mobile-tassle {
		display: inline-block;
		margin-left: -66rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
		.icon-pic {
			margin-right: 10rem;
		}
	}

	.header-desktop {
		display: none;
	}

	.header .navbar {
		padding-right: 2rem;
	}
}
@media only screen and (max-width: 790px) {
	.header .bar {
		width: 90% !important;
		margin-left: 2.5% !important;
	}
	.stuff {
		height: 60vw;
	}

	.header-mobile {
		display: flex;

		h1 {
			font-size: 20px;
			margin: 0.4rem 0 1rem 3rem !important;
			margin-right: 10rem !important;
			width: 100%;
			font-size: 20px !important;
			padding-bottom: 0;
		}
	}

	.mr-auto {
		margin-top: 0rem;
		margin-left: -37rem;
		.link-tag {
			width: 80%;
		}
		.nav-text {
			margin-right: 1rem;
			font-size: 13px;
		}
	}
	.mobile-tassle {
		display: inline-block;
		margin-left: -55rem !important;
		margin-top: -2rem;
		.icon-pic {
			margin-right: 4rem;
		}
	}

	.header-desktop {
		display: none;
	}

	.header .navbar {
		padding-right: 2rem;
		padding-left: 15rem;
		margin-left: 3rem;
	}

	.landing-navbar {
		margin-top: 0.2rem;
	}

	.mobile-tassle {
		display: inline-block;
		margin-left: -40rem !important;
		margin-top: 1rem;
		margin-bottom: 1rem;
		.icon-pic {
			margin-right: 30rem;
		}
	}
}

@media only screen and (max-width: 690px) {
	.header .bar {
		width: 90% !important;
		margin-left: 2.5% !important;
	}
	.stuff {
		height: 60vw;
	}

	.header-mobile {
		display: flex;

		h1 {
			font-size: 20px;
			margin: 0.4rem 0 1rem 3rem !important;
			margin-right: 10rem !important;
			width: 100%;
			font-size: 20px !important;
			padding-bottom: 0;
		}
	}

	.mr-auto {
		margin-top: 0rem;
		margin-left: -26.8rem;
		.link-tag {
			width: 80%;
		}
		.nav-text {
			margin-right: 1rem;
			font-size: 13px;
		}
	}
	.mobile-tassle {
		display: inline-block;
		margin-left: -40rem !important;
		margin-top: -2rem;
		.icon-pic {
			margin-right: 4rem;
		}
	}

	.header-desktop {
		display: none;
	}

	.header .navbar {
		padding-right: 0rem;
		margin-left: -16vw;
	}

	.landing-navbar {
		margin-top: 0.2rem;
	}

	.mobile-tassle {
		display: inline-block;
		margin-left: -60rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
		.icon-pic {
			margin-right: 10rem;
		}
	}
}

@media only screen and (max-width: 440px) {
	.stuff {
		margin-bottom: 11rem !important;
	}
	.header {
		width: 88% !important;
		margin-left: 6% !important;
		overflow: hidden;
		padding-bottom: 1.2rem;
		.navbar {
			margin-left: 0.3rem;
			margin-top: 1rem;
			font-size: 10px;
			padding: 0;
			padding-right: 2rem;
		}

		.nav-text {
			font-size: 13px;
			margin-bottom: -0.4rem;
		}
	}
	.header-mobile {
		display: flex;
		h1 {
			font-size: 20px;
			margin: 0.4rem 0 0 2rem !important;
			margin-right: 9rem !important;
			width: 100%;
			font-size: 16px !important;
			padding-bottom: 0;
			text-align: left;
		}
	}
	.header-desktop {
		display: none;
	}

	.mr-auto {
		margin-top: 0rem;
		margin-left: -16.8rem;
		text-align: left;
		.link-tag {
			width: 80%;
		}
	}

	.mobile-tassle {
		display: inline-block;
		margin-left: -16rem !important;
		margin-top: 1.4rem;
		margin-bottom: -0.1rem;
		.icon-pic {
			margin-right: 10rem;
		}
	}
}

@media only screen and (max-width: 400px) {
	.header {
		width: 88% !important;
		margin-left: 6% !important;
		overflow: hidden;
		padding-bottom: 1.2rem;
		.navbar {
			margin-left: 0rem;
			margin-top: 1rem;
			font-size: 10px;
			padding: 0;
			padding-right: 2rem;
		}

		.nav-text {
			font-size: 13px;
			margin-bottom: -0.4rem;
		}
	}
	.header-mobile {
		display: flex;
		h1 {
			font-size: 20px;
			margin: 0.4rem 0 0 1.3rem !important;
			margin-right: 9rem !important;
			width: 100%;
			font-size: 16px !important;
			padding-bottom: 0;
			text-align: left;
		}
	}
	.header-desktop {
		display: none;
	}

	.mr-auto {
		margin-top: 0rem;
		margin-left: -15rem;
		text-align: left;
		.link-tag {
			width: 80%;
		}
	}

	.mobile-tassle {
		display: inline-block;
		margin-left: -15.6rem !important;
		margin-top: 1.4rem;
		margin-bottom: -0.1rem;
		.icon-pic {
			margin-right: 10rem;
		}
	}
}
@media only screen and (max-width: 410px) {
	.header {
		width: 88% !important;
		margin-left: 6% !important;
		overflow: hidden;
		padding-bottom: 1.2rem;
		.navbar {
			margin-left: 1rem !important;
			margin-top: 1rem;
			font-size: 10px;
			padding: 0;
			padding-right: 2rem;
		}

		.nav-text {
			font-size: 13px;
			margin-bottom: -0.4rem;
		}
	}
	.header-mobile {
		display: flex;
		h1 {
			font-size: 20px;
			margin: 0.4rem 0 0 1.3rem !important;
			margin-right: 9rem !important;
			width: 100%;
			font-size: 16px !important;
			padding-bottom: 0;
			text-align: left;
		}
	}
	.header-desktop {
		display: none;
	}

	.mr-auto {
		margin-top: 0rem;
		margin-left: -17rem;
		text-align: left;
		.link-tag {
			width: 80%;
		}
	}

	.mobile-tassle {
		display: inline-block;
		margin-left: -17.6rem !important;
		margin-top: 1.4rem;
		margin-bottom: -0.1rem;
		.icon-pic {
			margin-right: 10rem;
		}
	}
}

@media only screen and (max-width: 400px) {
	.header {
		width: 88% !important;
		margin-left: 6% !important;
		overflow: hidden;
		padding-bottom: 1rem;
		.navbar {
			margin-left: -0.5rem !important;
			margin-top: 1rem;
			font-size: 10px;
			padding: 0;
			padding-right: 2rem;
		}

		.nav-text {
			font-size: 13px;
			margin-bottom: -0.4rem;
		}
	}
	.header-mobile {
		display: flex;
		h1 {
			font-size: 20px;
			margin: 0.4rem 0 0 1.3rem !important;
			margin-right: 9rem !important;
			width: 100%;
			font-size: 16px !important;
			padding-bottom: 0;
			text-align: left;
		}
	}
	.header-desktop {
		display: none;
	}

	.mr-auto {
		margin-top: 0rem;
		margin-left: -15.8rem;
		text-align: left;
		.link-tag {
			width: 80%;
		}
	}

	.mobile-tassle {
		display: inline-block;
		margin-left: -15.6rem !important;
		margin-top: 1.4rem;
		margin-bottom: -0.1rem;
		.icon-pic {
			margin-right: 10rem;
		}
	}
}

@media only screen and (max-width: 330px) {
	.header {
		width: 88% !important;
		margin-left: 6% !important;
		overflow: hidden;
		padding-bottom: 1.2rem;
		.navbar {
			margin-left: -2rem !important;
			margin-top: 1rem;
			font-size: 8px !important;
			padding: 0;
			padding-right: 2rem;
		}

		.nav-text {
			font-size: 13px;
			margin-bottom: -0.4rem;
		}
	}
	.header-mobile {
		display: flex;
		h1 {
			margin: 0.4rem 0 0 1.3rem !important;
			margin-right: 9rem !important;
			width: 100%;
			font-size: 14px !important;
			padding-bottom: 0;
			text-align: left;
		}
	}
	.header-desktop {
		display: none;
	}

	.mr-auto {
		margin-top: 0rem;
		margin-left: -13rem;
		text-align: left;
		.link-tag {
			width: 80%;
		}
	}

	.mobile-tassle {
		display: inline-block;
		margin-left: -12rem !important;
		margin-top: 1.4rem;
		margin-bottom: -0.1rem;
		.icon-pic {
			margin-right: 10rem;
		}
	}
}

.decal {
	padding: 4rem;
	padding-bottom: 0;
	text-align: center;
}

.decal .row {
	text-align: center;
	margin: 0;
}

.decal h5 {
	font-size: 40px;
	color: black;
	margin: auto;
}

.decal p {
	margin: 0 14rem;
	margin-bottom: 3rem;
	font-size: 130%;
	text-align: left;
}

@media only screen and (max-width: 2900px) {
	.decal h5 {
		margin-bottom: 2.5rem;
	}
}

@media only screen and (max-width: 1400px) {
	.decal {
		margin-top: 0rem;
		margin-bottom: 2rem;
	}

	.decal h5 {
		font-size: 140%;
		color: black;
		line-height: normal;
		margin-top: 0rem !important;
		margin-bottom: 2rem;
	}
}

@media only screen and (max-width: 800px) {
	.decal {
		margin-top: -36rem !important;
		margin-bottom: 2rem;
	}
	.decal p {
		margin: 0 10rem;
		margin-bottom: 10rem !important;
		font-size: 130%;
	}

	.decal h5 {
		font-size: 140%;
		color: black;
		line-height: normal;
		margin-top: 0rem !important;
		margin-bottom: 2rem !important;
	}
}

@media only screen and (max-width: 1100px) {
	.decal {
		margin-top: 0rem;
		margin-bottom: 3rem;
	}
	.decal p {
		margin: 0 5rem !important;
		margin-bottom: 2rem !important;
		font-size: 130%;
	}

	.decal h5 {
		font-size: 140%;
		color: black;
		line-height: normal;
		margin: auto;
		margin-bottom: 3rem;
	}
}

@media only screen and (max-width: 800px) {
	.decal h5 {
		font-size: 140%;
		color: black;
		line-height: normal;
		margin-top: 40rem !important;
		margin-bottom: -16rem;
	}

	.decal {
		padding: 3rem;
		margin-top: -100%;
		margin-bottom: 5rem;
	}
	.decal p {
		margin: auto;
		font-size: 100%;
	}
}

@media only screen and (max-width: 414px) {
	.decal {
		margin-top: -55rem !important;
		margin-bottom: 3rem;
	}
	.decal p {
		margin: 0rem 0rem 0rem 0rem !important;
		margin-bottom: 2rem !important;
		font-size: 90%;
	}

	.decal h5 {
		font-size: 140%;
		color: black;
		line-height: normal;
		margin: auto;
		margin-bottom: 3rem;
	}
}

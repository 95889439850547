.secret-page .image {
	max-width: 80%;
	margin-top: 2rem;
}

.secret-page p {
	margin-top: 3rem;
	font-size: 3rem;
}

.secret h3 {
	margin-bottom: 2rem;
}

.mailing {
	button {
		background-color: #3e4857 !important;
	}
	.mail-form {
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
	}
}

.mailing-content {
	padding: 5rem;
	padding-bottom: 0;
	.row {
		margin-bottom: 1rem;
	}

	@font-face {
		font-display: block;
		font-family: Roboto;
		src: url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/7529907e9eaf8ebb5220c5f9850e3811.woff2)
				format('woff2'),
			url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/25c678feafdc175a70922a116c9be3e7.woff)
				format('woff');
	}

	@font-face {
		font-display: fallback;
		font-family: Roboto;
		font-weight: 600;
		src: url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/6e9caeeafb1f3491be3e32744bc30440.woff2)
				format('woff2'),
			url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/71501f0d8d5aa95960f6475d5487d4c2.woff)
				format('woff');
	}

	@font-face {
		font-display: fallback;
		font-family: Roboto;
		font-weight: 700;
		src: url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/3ef7cf158f310cf752d5ad08cd0e7e60.woff2)
				format('woff2'),
			url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/ece3a1d82f18b60bcce0211725c476aa.woff)
				format('woff');
	}

	#sib-container input:-ms-input-placeholder {
		text-align: left;
		font-family: 'Helvetica', sans-serif;
		color: #c0ccda;
	}

	#sib-container input::placeholder {
		text-align: left;
		font-family: 'Helvetica', sans-serif;
		color: #c0ccda;
	}

	#sib-container textarea::placeholder {
		text-align: left;
		font-family: 'Helvetica', sans-serif;
		color: #c0ccda;
	}
}

@media only screen and (max-width: 430px) {
	.mailing-content {
		p {
			font-size: 100%;
		}
	}
}

.block {
	background-repeat: no-repeat !important;
	background-size: 90%;
	background-position: top;
	display: flex;
	margin: auto;
	width: 95%;
	padding-top: 2rem;
}

.block .col {
	padding: 2rem;
	background: #f5f5f5;
	border-radius: 0x;
	padding-bottom: 0.5rem;
	box-shadow: 10px 10px 10px grey;
}

.past .block .personPic {
	background-image: none;
	height: 10vw;
	margin-left: -3rem;
	margin-top: -1rem;
}

.past .personBlock h3 {
	text-align: left;
	font-size: 35px;
	margin-left: -6rem;
	margin-top: -1.4rem;
}

.past .personBlock hr {
	display: block;
	overflow: hidden;
	width: 110%;
	border-style: inset;
	border-width: 1px;
	border-color: black;
	margin-left: -6rem;
	margin-top: -1rem;
}

.past .personBlock .title {
	text-align: left;
	font-size: 20px;
	text-transform: uppercase !important;
	background-image: none;
	margin-left: -6rem;
	margin-top: -0.4rem;
}

.past .personBlock .date {
	text-align: left;
	font-size: 16px;
	text-transform: uppercase;
	color: grey;
	background-image: none;
	margin-left: -6rem;
	margin-top: -1rem;
}

.past .personBlock .content {
	text-align: left;
	background-image: none;
	font-size: 16px;
	margin-left: -6rem;
	margin-top: -0.5rem;
}

.past h4 {
	margin-top: 2rem;
}

.block {
	.passed-event {
		margin-left: -26rem !important;
		font-size: 15x !important;
	}
}
@media only screen and (max-width: 2800px) {
	.past .block .personPic {
		background-image: none;
		height: 15vw;
		margin-left: -3rem;
		margin-top: -1rem;
	}
}

@media only screen and (max-width: 1600px) {
	.past .block .personPic {
		background-image: none;
		height: 20vw;
		margin-left: -3rem;
		margin-top: 0rem;
	}
}

@media only screen and (max-width: 1100px) {
	.block {
		.passed-event {
			margin-left: -16em !important;
			font-size: 23px !important;
		}
	}
}

@media only screen and (max-width: 800px) {
	.block {
		.passed-event {
			margin-left: -8em !important;
			font-size: 10x !important;
		}
		u {
			margin-right: 0rem;
			margin-left: 4rem;
		}
	}
}

@media only screen and (max-width: 765px) {
	.past .block .personPic {
		background-image: none;
		height: 40vw;
		margin: auto;
		margin-bottom: 0rem;
	}

	.col {
		padding-top: 1rem !important;
	}
	.past .block {
		.content {
			margin: auto;
			font-size: 12px;
		}
		.title {
			margin: auto;
			font-size: 15px;
		}
		.date {
			margin: auto;
			font-size: 14px;
		}
		h3 {
			margin: auto;
			font-size: 30px;
		}
		hr {
			width: 100%;
			margin: auto;
			margin-top: -0.2rem;
		}
		u {
			font-size: 14px;
			margin-left: 5rem;
			margin-right: 0rem;
			text-align: center;
			justify-content: center;
		}
	}
}

@media only screen and (max-width: 400px) {
	.past .block .personPic {
		background-image: none;
		height: 40vw;
		margin: auto;
		margin-bottom: 0rem;
	}

	.block {
		.passed-event {
			margin-left: 0em !important;
			margin-top: 0.6rem;
			font-size: 16px !important;
		}
	}

	.col {
		padding-top: 1rem !important;
	}
	.past .block {
		.content {
			margin: auto;
			font-size: 12px;
		}
		.title {
			margin: auto;
			font-size: 15px;
		}
		.date {
			margin: auto;
			font-size: 14px;
		}
		h3 {
			margin: auto;
			font-size: 30px;
		}
		hr {
			width: 100%;
			margin: auto;
			margin-top: -0.2rem;
		}
		u {
			font-size: 14px;
			margin-left: 5rem;
			margin-right: 0rem;
			text-align: center;
			justify-content: center;
		}
	}
}

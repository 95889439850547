.committee-content {
	padding: 5rem;
	padding-bottom: 0;
	.row {
		margin-bottom: 4rem;
	}
	p {
		margin: 0 5rem;
		text-align: left;
	}

	.bottom-navbar {
		display: flex;
		justify-content: space-around;

		align-content: center;
		margin-bottom: 5rem;
		margin-top: 8rem;
		a {
			font-family: 'Karla', sans-serif;
			border-radius: 6px;
			background-color: #233748;
			color: #ffffff;
			font-style: normal;
			font-weight: normal;
			line-height: 35px;
			text-align: center;
			text-transform: uppercase;
			border-color: transparent;
			box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.18);
			padding: 1vw 2vw 1vw 2vw;
			font-size: 2vw;
			margin: 0;
			text-decoration: none;
		}
	}
}

@media only screen and (max-width: 1400px) {
	.committee-content {
		margin-top: 0rem;
		p {
			font-size: 120%;
			margin-right: 5rem;
			margin-left: 5rem;
		}
	}
}

@media only screen and (max-width: 1100px) {
	.committee-content {
		margin-top: 3rem;
		p {
			font-size: 110%;
			margin-right: 3rem;
			margin-left: 3rem;
		}
	}
}

@media only screen and (max-width: 800px) {
	.committee-content {
		margin-top: 4rem;
		p {
			font-size: 110%;
			margin-right: 0rem;
			margin-left: 0rem;
		}
	}
}

@media only screen and (max-width: 430px) {
	.committee-content {
		margin-top: 1rem;
		p {
			font-size: 90%;
			margin-right: -1rem;
			margin-left: -1rem;
		}

		.bottom-navbar {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 50vw;
			margin-bottom: 5rem;
			margin-top: 4rem;
			a {
				font-size: 100%;
			}
		}
	}
}

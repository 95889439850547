.team-card {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 2rem;

	.headshot {
		border-radius: 4px;
		position: relative;
		display: flex;
		flex-direction: column;
		height: 30vw;
		width: 23vw;
		margin: 0;
		.portrait {
			border-radius: 4px;
			object-fit: cover;
			height: inherit;
			width: inherit;
		}
		.bio {
			border-radius: 4px;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			background-color: #233748;
			padding: 1rem;
			text-align: center;
			color: white;
			font-size: 1vw;
			transition: opacity 1s;
			margin-bottom: 0px;
		}
		.bio:hover {
			opacity: 0.8;
		}
	}
	.name {
		font-size: 2.5vmax;
		margin: 0;
		text-transform: none;
	}
	.role {
		margin-top: -1rem;
		font-size: 2.1vmax;
	}
}

@media only screen and (max-width: 430px) {
	.team-card {
		.headshot {
			height: 100vw;
			width: 75vw;
			.bio {
				font-size: 14px;
			}
		}
		.name {
			font-size: 2rem;
			width: 100%;
		}
		.role {
			font-size: 1.5rem;
			margin: 0;
		}
	}
}

.convo-content {
	padding: 4rem;
	padding-bottom: 0;
	text-align: center;
	overflow: hidden;
	margin-top: 1rem;
	padding-bottom: 2rem;
}

.convo-content h5 {
	font-size: 40px;
	color: black;
	margin: 0;
}

.convos .medium {
	font-size: 40px;
	color: black;
	margin: auto;
	margin-top: 4rem;
	margin-left: 15rem;
	margin-right: 15rem;
}

.convo-content .row {
	margin-top: 1rem;
	margin-bottom: 3.5rem;
	text-align: center;
	margin: 0;
}

.convo-content p {
	font-size: 170% !important;
	font-weight: bold;
	margin-top: 2rem !important;
	margin-left: 1rem !important;
	color: white;
	text-align: left !important;
	padding: 1rem;
}

.convo-content link {
	margin-left: 2em !important;
}

.convo-content h6 {
	position: relative;
	font-size: 120% !important;
	margin-top: -4rem !important;
	margin-right: -0.7rem;
	color: white;
	text-align: right !important;
	padding: 2.5rem;
}

.convo-content .blank {
	background-position: center;
	background-image: url(../img/blank.png);
	background-size: 95%;
	background-repeat: no-repeat;
}

.convo-content .fall2019 {
	background-position: center;
	background-image: url(../img/lineup1.png);
	background-repeat: no-repeat;
	background-size: 95%;
}

.convo-content .discourse {
	background-position: center;
	background-image: url(../img/discourse.png);
	background-repeat: no-repeat;
	background-size: 95%;
}
.convo-content .rand {
	background-position: center;
	background-position: center;
	background-image: url(../img/rand.png);
	background-size: 95%;
	background-repeat: no-repeat;
}

.convo-content .ellen {
	background-position: center;
	background-image: url(../img/ellen.png);
	background-size: 95%;
	background-repeat: no-repeat;
}
.convo-content .homeland {
	background-position: center;
	background-image: url(../img/homeland.png);
	background-size: 95%;
	background-repeat: no-repeat;
}
.convo-content .feng {
	background-position: center;
	background-image: url(../img/feng.png);
	background-size: 95%;
	background-repeat: no-repeat;
}
.convo-content .politico {
	background-position: center;
	background-image: url(../img/politico.png);
	background-size: 95%;
	background-repeat: no-repeat;
}
.convo-content .host {
	background-position: center;
	background-image: url(../img/host.png);
	background-size: 95%;
	background-repeat: no-repeat;
}
.convo-content .hey-vsauce-michael-here {
	background-position: center;
	background-image: url(../img/vsauce.png);
	background-size: 95%;
	background-repeat: no-repeat;
}
.convo-content .josh {
	background-position: center;
	background-image: url(../img/josh.png);
	background-size: 95%;
	background-repeat: no-repeat;
}
.convo-content .spring {
	background-position: center;
	background-image: url(../img/lineup2.png);
	background-size: 95%;
	background-repeat: no-repeat;
}

.convo-content a {
	text-decoration: none;
}

@media only screen and (max-width: 2800px) {
	.convo-content {
		padding: 4rem;
		padding-bottom: 0;
		text-align: center;
		overflow: hidden;
		margin-top: 2rem;
		margin-bottom: 2rem;
		padding-bottom: 2;
	}
	.convo-content .row {
		margin-top: 1rem;
		margin-bottom: 0rem;
		text-align: center;
	}

	.convo-content p {
		font-size: 170% !important;
	}

	.convo-content h6 {
		font-size: 150% !important;
	}
}

@media only screen and (max-width: 1600px) {
	.convo-content {
		padding: 4rem;
		padding-bottom: 0;
		text-align: center;
		overflow: hidden;
		margin-top: 1rem;
	}
}

@media only screen and (max-width: 1366px) {
	.convos h3 {
		margin-top: -5em !important;
	}
}

@media only screen and (max-width: 1145px) {
	.convo-content h5 {
		font-size: 20px;
		color: black;
		margin: 0;
	}
	.convos h3 {
		margin-top: 0em !important;
		margin-bottom: 2rem !important;
		font-size: 30px !important;
		line-height: 40px !important ;
		margin-right: 5rem !important;
		margin-left: 5rem !important;
	}

	.convo-content .row {
		margin-top: 0rem;
		margin-bottom: -1rem;
		text-align: center;
	}

	.convo-content p {
		font-size: 120% !important;
	}

	.convo-content h6 {
		font-size: 80% !important;
	}
}

@media only screen and (max-width: 996px) {
	.convos h3 {
		margin-top: -1em !important;
		margin-bottom: 6rem !important;
		font-size: 30px !important;
		line-height: 40px !important ;
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}

	.convo-content {
		margin-top: -10rem;
	}
	.convo-content h5 {
		font-size: 14px;
		color: black;
	}

	.convo-content .row {
		margin-top: 0rem;
		margin-bottom: -2rem;
		text-align: center;
	}

	.convo-content p {
		font-size: 80% !important;
		margin-top: 2rem !important;
		margin-left: 1rem !important;
	}
	.convo-content h6 {
		font-size: 70% !important;
		margin-right: -2rem !important;
	}
}
@media only screen and (max-width: 576px) {
	.convo-content {
		padding: 1.5rem;
		padding-bottom: 0;
		text-align: center;
		overflow: hidden;
	}
	.convo-content .blank {
		background-size: 100%;
	}

	.convo-content .fall2019 {
		background-size: 100%;
	}

	.convo-content .discourse {
		background-size: 100%;
	}
	.convo-content .rand {
		background-size: 100%;
	}

	.convo-content .ellen {
		background-size: 100%;
	}
	.convo-content .homeland {
		background-size: 100%;
	}
	.convo-content .feng {
		background-size: 100%;
	}
	.convo-content .politico {
		background-size: 100%;
	}
	.convo-content .host {
		background-size: 100%;
	}
	.convo-content .hey-vsauce-michael-here {
		background-size: 100%;
	}
	.convo-content .josh {
		background-size: 100%;
	}
	.convo-content .spring {
		background-size: 100%;
	}
}
@media only screen and (max-width: 440px) {
	.convos h3 {
		margin-top: 2rem !important;
		margin-bottom: 0rem !important;
		font-size: 20px !important;
		line-height: 40px !important ;
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.convo-content {
		margin-top: -30rem;
	}
	.convo-content h5 {
		font-size: 14px;
		color: black;
	}

	.convo-content .row {
		margin-top: 0rem;
		margin-bottom: -4rem;
		text-align: center;
	}

	.convo-content p {
		font-size: 100% !important;
		margin-top: 2rem !important;
		margin-left: 3rem !important;
	}

	.convo-content h6 {
		font-size: 70% !important;
		margin-right: -2rem !important;
	}

	.convos .medium {
		font-size: 16px;
		margin: auto;
		margin-left: 2rem;
		margin-right: 2rem;
		margin-top: 1rem;
		line-height: 26px;
	}

	.convo-content .row {
		margin: -1rem 0 1rem 0;
	}

	.convo-content .col {
		margin: -1rem 0 -2rem 0;
	}

	.convo-content {
		margin-top: 0rem;
	}

	.convo-content p {
		font-size: 80% !important;
		margin-top: 1.4rem !important;
		margin-left: -1rem !important;
	}

	.convo-content h6 {
		font-size: 70% !important;
		margin-right: -2rem !important;
		padding: 3rem;
	}
}

@media only screen and (max-width: 375px) {
	.convo-content h6 {
		font-size: 70% !important;
		margin-right: -2rem !important;
		padding: 2rem;
	}
}

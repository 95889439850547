.card-content .image {
	z-index: -1;
}

.card-content {
	border: none !important;
}

.card-content p {
	text-align: right !important;
	margin-right: 4.5em;
	font-size: 25px;
	background-color: white;
}

.card-content .background {
	background-color: white;
}

.card-content .card-back p {
	color: #233748;
	z-index: 1;
}
.card-content .card-back {
	margin-top: -20rem;
}

@media only screen and (max-width: 2800px) {
	.card-content p {
		margin-right: 18rem;
		font-size: 25px;
	}
}

@media only screen and (max-width: 1600px) {
	.card-content p {
		margin-right: 7rem;
		font-size: 20px;
	}
}

@media only screen and (max-width: 1400px) {
	.card-content {
		display: none;
	}
	.contact-mobile {
		display: flex;
		margin-top: 1rem;
	}

	.contact-mobile .image {
		width: 60vw;
		height: 40vw;
		z-index: -1;
		margin: auto;
		margin-left: 18rem;
	}

	.contact-mobile p {
		color: #233748 !important;
		z-index: 1 !important;
		margin-top: 4rem;
		margin-left: -31rem !important;
		font-size: 26px;
	}
	.bottom-space {
		padding-bottom: 2rem;
	}
}

@media only screen and (max-width: 800px) {
	.card-content {
		display: none;
	}
	.contact-mobile {
		display: flex;
		margin-top: 1rem;
	}

	.contact-mobile .image {
		width: 100vw;
		height: 68vw;
		z-index: -1;
	}

	.card-back {
		margin-top: 2rem;
	}
	.contact-mobile p {
		color: #233748 !important;
		z-index: 1 !important;
		margin-top: 3rem;
		margin-left: -27rem !important;
		font-size: 25px;
	}
	.bottom-space {
		padding-bottom: 2rem;
	}
}

@media only screen and (max-width: 1100px) {
	.card-content {
		display: none;
	}
	.contact-mobile {
		display: flex;
		margin-top: 1rem;
	}

	.contact-mobile .image {
		width: 100vw;
		height: 68vw;
		z-index: -1;
		margin: 0;
	}

	.contact-mobile p {
		color: #233748 !important;
		z-index: 1 !important;
		margin-top: 5rem;
		margin-left: -38rem !important;
		font-size: 35px !important;
	}
	.bottom-space {
		padding-bottom: 2rem;
	}
}

@media only screen and (max-width: 800px) {
	.card-content {
		display: none;
	}
	.contact-mobile {
		display: flex;
		margin-top: 1rem;
	}

	.contact-mobile .image {
		width: 100vw;
		height: 68vw;
		z-index: -1;
	}

	.card-back {
		margin-top: 2rem;
	}
	.contact-mobile p {
		color: #233748 !important;
		z-index: 1 !important;
		margin-top: 3rem;
		margin-left: -27rem !important;
		font-size: 25px !important;
	}
	.bottom-space {
		padding-bottom: 2rem;
	}
}

@media only screen and (max-width: 440px) {
	.card-content {
		display: none;
	}
	.contact-mobile {
		display: flex;
		margin-top: 1rem;
	}

	.contact-mobile .image {
		width: 100vw;
		height: 68vw;
		z-index: -1;
	}
	.card-back {
		margin-top: 1.8rem;
	}

	.contact-mobile p {
		color: #233748 !important;
		z-index: 1 !important;
		margin-top: 1rem;
		margin-left: -14rem !important;
		font-size: 13px !important;
	}
	.bottom-space {
		padding-bottom: 2rem;
	}
}
